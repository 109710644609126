import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "rebooking"
    }}>{`Rebooking`}</h2>
    <p>{`Throughout the lifespan of a reservation there can occur modifications to the linked departure.
Depending on the modifications, they may require updating the reservation to ensure consistency with the scheduled departures and available seats.
We refer to this process of updating reservations as `}<strong parentName="p">{`rebooking`}</strong>{`. Rebooking is performed automatically when departures are modified. No actions are required by the clients during this process.
Any changes to reservations will be published to the kafka topic `}<inlineCode parentName="p">{`seating-reservations-v1-<env>[-<organisation id>]`}</inlineCode>{` and can be consumed by interested parties.
Additionally, any affected customers are notified about any changes to their reservations due to rebooking through SMS and/or e-mail.`}</p>
    <h3 {...{
      "id": "what-can-cause-rebooking"
    }}>{`What can cause rebooking?`}</h3>
    <p>{`Currently, a departure can be modified through several means:`}</p>
    <ul>
      <li parentName="ul">{`Updating timetable data and associated material(vehicles) through NeTEx formatted files. Including:`}
        <ul parentName="li">
          <li parentName="ul">{`Replacement (rail-replacement buses) - One or more departures are cancelled and replaced by others, usually with different stop places.`}</li>
          <li parentName="ul">{`Material change - One or more elements (e.g. train carriages) are removed, added or changed leading to a change in available seats.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Overriding material on a specific departure.`}
        <ul parentName="li">
          <li parentName="ul">{`One or more elements (e.g. train carriages) are removed, added or changed leading to a change in available seats.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Adding closures that requires relocation to one or a set of departures. A closure is one of the following:`}
        <ul parentName="li">
          <li parentName="ul">{`Element closure - one or more elements are closed for future reservations.`}</li>
          <li parentName="ul">{`Seat closure - one or more seats are closed for future reservations.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`If any of these modifications are made, rebooking is automatically triggered on affected reservations.`}</p>
    <h3 {...{
      "id": "what-happens-in-the-rebooking-process"
    }}>{`What happens in the rebooking process?`}</h3>
    <p>{`When rebooking is triggered for a reservation, a new version the reservation is created with status `}<inlineCode parentName="p">{`DRAFT`}</inlineCode>{`.
One or more of the following will be updated to reflect the changes in scheduled departures and available seats:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`departure`}</strong>{` - the linked departure may be changed if the originally requested departure is replaced by another.`}</li>
      <li parentName="ul"><strong parentName="li">{`stop places`}</strong>{` - the origin and/or destination of the reservation may be changed to reflect changes in stop places`}</li>
      <li parentName="ul"><strong parentName="li">{`reservation lines`}</strong>{` - the reservation lines may be updated with new seats or compartments if the previous seats or compartments are no longer available.`}
        <ul parentName="li">
          <li parentName="ul">{`In some cases no new seats or compartments that fit the required seating properties of the reservation line are available. In which case the reservation lines are changed to `}<inlineCode parentName="li">{`FailedRebookingReservationLines`}</inlineCode>{` to indicate that they no longer occupy any seats or compartments.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Additionally, a `}<strong parentName="p">{`rebooking status`}</strong>{` may be set to reflect the outcome of the rebooking process.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rebooking status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`When?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no changes are made to the stop places and reservation lines, but the departure may be updated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`REBOOKED_DRAFT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`before rebooking the reservation still had status `}<inlineCode parentName="td">{`DRAFT`}</inlineCode>{`, but changes has been made to the departure, stop places and/or reservation lines`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MANUAL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`either no replacing stop places or seats/compartments were found, and affected reservation lines were changed to `}<inlineCode parentName="td">{`FailedRebookingReservationLines`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SUGGESTED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`before rebooking the reservation had status `}<inlineCode parentName="td">{`CONFIRMED`}</inlineCode>{`, but changes has been made to the stop places and/or seats/compartments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ACCEPTED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a `}<inlineCode parentName="td">{`SUGGESTED`}</inlineCode>{` reservation has been accepted. Usually a manual step performed through Entur Plass UI`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Rebooked reservations with rebooking status `}<inlineCode parentName="p">{`MANUAL`}</inlineCode>{` or `}<inlineCode parentName="p">{`SUGGESTED`}</inlineCode>{` cannot be updated to reservation status `}<inlineCode parentName="p">{`CONFIRMED`}</inlineCode>{` until any `}<inlineCode parentName="p">{`FailedRebookingReservationLines`}</inlineCode>{` has been updated with seats/compartments and the reservation subsequently is updated to status `}<inlineCode parentName="p">{`ACCEPTED`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "what-happens-after-the-rebooking-process"
    }}>{`What happens after the rebooking process?`}</h3>
    <p>{`The rebooking process is considered finished either:`}</p>
    <ul>
      <li parentName="ul">{`when a rebooked reservation receives rebooking status `}<inlineCode parentName="li">{`null`}</inlineCode>{` or `}<inlineCode parentName="li">{`REBOOKED_DRAFT`}</inlineCode></li>
      <li parentName="ul">{`when a successfully rebooked reservation that received status `}<inlineCode parentName="li">{`SUGGESTED`}</inlineCode>{` has been manually updated with status `}<inlineCode parentName="li">{`ACCEPTED`}</inlineCode>{` and reservation status `}<inlineCode parentName="li">{`CONFIRMED`}</inlineCode></li>
      <li parentName="ul">{`or when a failed rebooked reservation that received status `}<inlineCode parentName="li">{`MANUAL`}</inlineCode>{` has been manually fixed with new seats and subsequently is updated to rebooking status `}<inlineCode parentName="li">{`ACCEPTED`}</inlineCode>{` and reservation status `}<inlineCode parentName="li">{`CONFIRMED`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Any changes made to the reservation throughout the process will be published to the kafka topic `}<inlineCode parentName="p">{`seating-reservations-v1-<env>[-<organisation id>]`}</inlineCode>{` and can be consumed by interested parties. No actions are required by the clients during this process.`}</p>
    <p>{`In case a failed rebooked reservation is impossible to fix with new stop places and/or seats/compartments the reservation will currently remain with rebooking status `}<inlineCode parentName="p">{`MANUAL`}</inlineCode>{` and reservation status `}<inlineCode parentName="p">{`DRAFT`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "notifications"
    }}>{`Notifications`}</h3>
    <p>{`When a `}<inlineCode parentName="p">{`CONFIRMED`}</inlineCode>{` reservation is rebooked to new stop places and/or new seat/compartments, and the changes are subsequently `}<inlineCode parentName="p">{`ACCEPTED`}</inlineCode>{` and `}<inlineCode parentName="p">{`CONFIRMED`}</inlineCode>{`, a notification is sent to the affected customer. The customer is only notified if they have registered contact information. The notification is sent through SMS and/or e-mail. Additionally an order-note with the notification text is added to the affected order.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      